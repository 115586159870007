const ErmType = {
  SET_ERM_LIST: "SET_ERM_LIST",
  UPDATE_ERM_KYC: "UPDATE_ERM_KYC",
  UPDATE_BANK_DETAILS: "UPDATE_BANK_DETAILS",
  GET_ERM_DETAILS: "GET_ERM_DETAILS",
  ACCOUNT_STATUS: "ACCOUNT_STATUS",
  KYC_APPROVAL: "KYC_APPROVAL",
};

export interface GetERM {
  search: string;
  limit: number;
  page: number;
  type: string;
}

export interface UpdateERMKYC {
  id: number;
  panCardNo: any;
  panCardImage: any;
  aadhaarCardNo: any;
  aadhaarCardFrontImage: any;
  aadhaarCardBackImage: any;
  agreementImage: any;
  kycStatus: any;
  kycReason: any;
}

export interface UpdateERMBankDetails {
  id: number;
  bankName: any;
  accountNumber: any;
  branchName: any;
  ifscCode: any;
}

export interface GetERMDetails {
  id: number;
}

export interface UpdateERMPanelStatus {
  id: number;
  status: string;
}

export interface updateERMKYCApproval {
  id: number;
  status: string;
  reason: string;
}

export default ErmType;
