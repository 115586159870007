import RegistrationType from "../type/registrationType";

export interface RegistrationAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  registrationList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const RegistrationReducers = (
  state = INITIAL_STATE,
  action: RegistrationAction
) => {
  switch (action.type) {
    case RegistrationType.SET_REG_LIST:
      return {
        ...state,
        registrationList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default RegistrationReducers;
