import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import EmployerType, {
  CreateEmployer,
  GetEmployer,
  GetEmployerDetails,
  UpdateEmployer,
  UpdateEmployerKYC,
  GetEMCPaymentList,
  ApproveEMCPayment,
} from "../type/employerType";
import toast from "react-hot-toast";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const EmployerAction = {
  createEmployer:
    (data: CreateEmployer): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employer/create",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: EmployerType.CREATE_EMPLOYER,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getEmployer:
    (data: GetEmployer): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employer/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            type: data.type,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: EmployerType.SET_EMPLOYER_LIST,
          payload: {
            data: result.data.data.employers.data,
            currentPage: result.data.data.employers.current_page,
            lastPage: result.data.data.employers.last_page,
            totalData: result.data.data.employers.total,
            from: result.data.data.employers.from,
            to: result.data.data.employers.to,
            totalApprovedKYC: result.data.data.totalApprovedKYC,
            totalPendingKYC: result.data.data.totalPendingKYC,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateEmployer:
    (data: UpdateEmployer): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employer/update",
          {
            id: data.id,
            name: data.name,
            email: data.email,
            mobile: data.mobile,
            no_of_employee: data.no_of_employee,
            state: data.state,
            district: data.district,
            pincode: data.pincode,
            address: data.address,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: EmployerType.UPDATE_EMPLOYER,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },

  updateEmployerKYC:
    (data: UpdateEmployerKYC): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employer/kyc",
          {
            id: data.id,
            gst_no: data.gst_no,
            gst_img: data.gst_img,
            pancard_no: data.pancard_no,
            pancard_img: data.pancard_img,
            msme_no: data.msme_no,
            msme_img: data.msme_img,
            partnership_no: data.partnership_no,
            partnership_img: data.partnership_img,
            rc_no: data.rc_no,
            rc_img: data.rc_img,
            moa_no: data.moa_no,
            moa_img: data.moa_img,
            aoa_no: data.aoa_no,
            aoa_img: data.aoa_img,
            coi_no: data.coi_no,
            coi_img: data.coi_img,
            trust_no: data.trust_no,
            trust_img: data.trust_img,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: EmployerType.UPDATE_EMPLOYER_KYC,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },

  getEmployerDetails:
    (data: GetEmployerDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "employer/details/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        dispatch({
          type: EmployerType.GET_EMPLOYER_DETAILS,
          payload: result.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getEMCPaymentList:
    (data: GetEMCPaymentList): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "employer/get-payment-list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: EmployerType.SET_EMC_PAYMENT_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  approveEMCPayment:
    (data: ApproveEMCPayment): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      // try {
      const token: string | any = localStorage.getItem(ADMIN_TOKEN);
      const result: any = await axios.post(
        BASE_URL + "employer/approve-payment",
        {
          id: data.id,
          payment_status: data.payment_status,
          reject_reason: data.reject_reason,
        },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        },
      );

      dispatch({
        type: EmployerType.APPROVE_EMC_PAYMENT,
      });
      if (result.data.status === true) {
        toast.success(result.data.msg);
      } else {
        toast.error(result.data.msg);
      }
      // } catch (error) {
      //   console.log(error);
      // }
    },
};

export default EmployerAction;
