import EmployeeType from "../type/employeeType";

export interface RegistrationAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  employeeList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  totalApprovedKYC: 0,
  totalPendingKYC: 0,
  employerDetails: [],
};

const EmployeeReducers = (
  state = INITIAL_STATE,
  action: RegistrationAction
) => {
  switch (action.type) {
    case EmployeeType.GET_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
        totalApprovedKYC: action.payload.totalApprovedKYC,
        totalPendingKYC: action.payload.totalPendingKYC,
      };

    case EmployeeType.UPDATE_EMPLOYEE_KYC:
      return {
        ...state,
      };

    case EmployeeType.APPROVE_EMPLOYEE_KYC:
      return {
        ...state,
      };

    case EmployeeType.GET_EMPLOYER_DETAILS:
      return {
        ...state,
        employerDetails: action.payload,
      };

    default:
      return state;
  }
};

export default EmployeeReducers;
