import { useState } from "react";
import { useDispatch } from "react-redux";
import EmployeeAction from "../../stores/action/employeeAction";

function UpdateKYCModal({
  modal,
  setModal,
  getEmployeeData,
  editableData,
}: any) {
  const [loading, setLoading] = useState<any>();
  const [kycStatus, setKYCStatus] = useState<any>();
  const [reason, setReason] = useState<any>();
  const dispatch = useDispatch<any>();
  const handelSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        EmployeeAction.approveEmployeeKYC({
          id: editableData.id,
          kyc_status: kycStatus,
          kyc_reject_reason: reason,
        }),
      );
      closeModal();
      getEmployeeData();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <form onSubmit={handelSubmit}>
            <div className="modal-header">
              <h5 className="modal-title">Approve Employee KYC </h5>
              <button
                type="button"
                onClick={closeModal}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 col-sm-6 portfolio-item web-design apps psd mb-1">
                  <div className="portfolio-thumb in">
                    <a href="#" className="main-link">
                      <img
                        className="img-responsive img-center"
                        src={editableData.pan_img}
                        alt=""
                      />
                      <span className="project-title">PAN</span>
                      <span className="overlay-mask"></span>
                    </a>
                  </div>
                  <h5 className="text-center mt-2">
                    PAN No. {editableData.pan_no}
                  </h5>
                </div>
                <div className="col-md-6 col-sm-6 portfolio-item web-design apps psd mb-1">
                  <div className="portfolio-thumb in">
                    <a href="#" className="main-link">
                      <img
                        className="img-responsive img-center"
                        src={editableData.aadhar_img}
                        alt=""
                      />
                      <span className="project-title">Aadhar</span>
                      <span className="overlay-mask"></span>
                    </a>
                  </div>
                  <h5 className="text-center mt-2">
                    Aadhar No. {editableData.aadhar_no}
                  </h5>
                </div>
                <div className="col-md-6">
                  <label>
                    Select KYC Status <span className="text-danger">*</span>
                  </label>
                  <div className="form-group">
                    <select
                      className="form-control"
                      value={kycStatus}
                      onChange={(e) => setKYCStatus(e.target.value)}
                    >
                      <option value="">Select KYC Status</option>
                      <option value="VERIFIED">Approved</option>
                      <option value="REJECTED">Rejected</option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-md-12"
                  style={{
                    display: kycStatus === "REJECTED" ? "block" : "none",
                  }}
                >
                  <label>
                    Reason for Rejection <span className="text-danger">*</span>
                  </label>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      style={{ height: "50px" }}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Approve KYC Details
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateKYCModal;
