import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import EmployerAction from "../../stores/action/employerAction";
import ApproveEMCPaymentModal from "../../components/employer/ApproveEMCPaymentModal";

interface EMCPayment {
  id: number;
  date: string;
  emp_no: string;
  cName: string;
  cEmail: string;
  total_amount: number;
  with_margin_total_amount: number;
  pay_slip: string;
  payment_status: string;
}

function EMCPaymentList() {
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch<any>();
  const [modal, setModal] = useState<boolean>(false);
  const [editableData, setEditableData] = useState<EMCPayment | null>(null);

  const { emcPaymentList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.employer);

  const maxPagesToShow = 5;

  const pagination = useMemo(() => {
    const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  }, [currentPage, lastPage]);

  const fetchEMCPaymentList = useCallback(async () => {
    await dispatch(
      EmployerAction.getEMCPaymentList({
        search,
        limit,
        page,
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    fetchEMCPaymentList();
  }, [fetchEMCPaymentList]);

  const openModal = (data: EMCPayment) => {
    setEditableData(data);
    setModal(true);
  };

  return (
    <WebLayout pageName="EMCPaymentList">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col">
                <ul className="breadcrumb breadcrumb-style">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">EMC Payment List</h4>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">EMC Payment List</li>
                </ul>
              </div>
            </div>
          </div>
          <ApproveEMCPaymentModal
            modal={modal}
            setModal={setModal}
            editableData={editableData}
            emcPaymentListData={fetchEMCPaymentList}
          />
          <div className="card">
            <div className="row g-3 px-4 mt-1 d-flex justify-content-between align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  value={limit} // Set the value prop to reflect the current state
                  onChange={(e) => setLimit(Number(e.target.value))} // Update the state with the selected value
                  id="defaultSelect"
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
            <div className="body">
              <div className="table-responsive">
                <table className="table table-hover table-checkable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>EMC ID</th>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Total Amount</th>
                      <th>With Margin (12%)</th>
                      <th>Pay Slip</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emcPaymentList.length > 0 ? (
                      emcPaymentList.map(
                        (dataList: EMCPayment, index: number) => (
                          <tr key={index}>
                            <td className="center">{index + 1}</td>
                            <td className="center">{dataList?.date}</td>
                            <td className="center">{dataList?.emp_no}</td>
                            <td className="center">{dataList?.cName}</td>
                            <td className="center">{dataList?.cEmail}</td>
                            <td className="center">
                              <i className="fa rupee"></i>{" "}
                              {dataList?.total_amount}
                            </td>
                            <td className="center">
                              <i className="fa rupee"></i>{" "}
                              {dataList?.with_margin_total_amount}
                            </td>
                            <td>
                              <a href={dataList?.pay_slip} target="_blank">
                                <img
                                  src={dataList?.pay_slip}
                                  alt="Pay Slip"
                                  width={100}
                                />
                              </a>
                            </td>
                            <td>
                              {dataList?.payment_status === "PENDING" ? (
                                <a
                                  href="#"
                                  className="label label-warning rounded-0"
                                >
                                  {dataList?.payment_status}
                                </a>
                              ) : dataList?.payment_status === "APPROVED" ? (
                                <a
                                  href="#"
                                  className="label label-success rounded-0"
                                >
                                  {dataList?.payment_status}
                                </a>
                              ) : dataList?.payment_status === "REJECTED" ? (
                                <a
                                  href="#"
                                  className="label label-danger rounded-0"
                                >
                                  {dataList?.payment_status}
                                </a>
                              ) : null}
                            </td>
                            <td>
                              <a
                                href="#"
                                onClick={() => openModal(dataList)}
                                className="label label-primary rounded-0"
                              >
                                Approve
                              </a>
                            </td>
                          </tr>
                        ),
                      )
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No matching records found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {emcPaymentList?.length > 0 && (
                <div className="row mt-2 justify-content-between">
                  <div className="col-md-auto me-auto">
                    <div className="dt-info">
                      Showing {from} to {to} of {totalData} entries
                    </div>
                  </div>
                  <div className="col-md-auto ms-auto">
                    <div className="dt-paging paging_full_numbers">
                      <ul className="pagination">
                        {/* First Page */}
                        <li
                          className={`dt-paging-button page-item ${
                            page === 1 ? "disabled" : ""
                          }`}
                          onClick={() => page !== 1 && setPage(1)}
                        >
                          <a
                            className="page-link first"
                            aria-controls="tableGroup"
                            aria-disabled={page === 1}
                            aria-label="First"
                            data-dt-idx="first"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>

                        {/* Previous Page */}
                        <li
                          className={`dt-paging-button page-item prev ${
                            page === 1 ? "disabled" : ""
                          }`}
                          onClick={() => page !== 1 && setPage(page - 1)}
                        >
                          <a
                            className="page-link previous"
                            aria-controls="tableGroup"
                            aria-disabled={page === 1}
                            aria-label="Previous"
                            data-dt-idx="previous"
                          >
                            <span aria-hidden="true">&lt;</span>
                          </a>
                        </li>

                        {/* Page Numbers */}
                        {pagination.map((p: number) => (
                          <li
                            key={p}
                            className={`dt-paging-button page-item ${
                              page === p ? "active" : ""
                            }`}
                            onClick={() => setPage(p)}
                          >
                            <a
                              className="page-link"
                              aria-controls="tableGroup"
                              aria-current="page"
                              data-dt-idx="0"
                            >
                              {p}
                            </a>
                          </li>
                        ))}

                        {/* Next Page */}
                        <li
                          className={`dt-paging-button page-item next ${
                            page === lastPage ? "disabled" : ""
                          }`}
                          onClick={() => page !== lastPage && setPage(page + 1)}
                        >
                          <a
                            className="page-link next"
                            aria-controls="tableGroup"
                            aria-label="Next"
                            data-dt-idx="next"
                          >
                            <span aria-hidden="true">&gt;</span>
                          </a>
                        </li>

                        {/* Last Page */}
                        <li
                          className={`dt-paging-button page-item ${
                            page === lastPage ? "disabled" : ""
                          }`}
                          onClick={() => page !== lastPage && setPage(lastPage)}
                        >
                          <a
                            className="page-link last"
                            aria-controls="tableGroup"
                            aria-disabled={page === lastPage}
                            aria-label="Last"
                            data-dt-idx="last"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EMCPaymentList;
