const StateWiseSalaryType = {
    ADD_STATE_WISE_SALARY: "ADD_STATE_WISE_SALARY",
    GET_STATE_WISE_SALARY_LIST: "GET_STATE_WISE_SALARY_LIST",
    UPDATE_STATE_WISE_SALARY: "UPDATE_STATE_WISE_SALARY",
};

export interface AddStateWiseSalaryType {
    state: string;
    amount: string;
}

export interface GetStateWiseSalaryType {
    search: any;
    limit: number;
    page: number;
}

export interface UpdateStateWiseSalaryType {
    id: number;
    amount: string;
}

export default StateWiseSalaryType;
