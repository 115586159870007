import StateWiseSalaryType from "../type/stateWiseSalaryType";


export interface StateWiseSalaryAction {
    type: string;
    payload?: any;
}

const INITIAL_STATE = {
    stateWiseSalaryList: [],
    currentPage: 0,
    lastPage: 0,
    totalData: 0,
    from: 0,
    to: 0,
};

const StateWiseSalaryReducers = (state = INITIAL_STATE, action: StateWiseSalaryAction) => {
    switch (action.type) {
        case StateWiseSalaryType.GET_STATE_WISE_SALARY_LIST:
            return {
                ...state,
                stateWiseSalaryList: action.payload.data,
                currentPage: action.payload.currentPage,
                lastPage: action.payload.lastPage,
                totalData: action.payload.totalData,
                from: action.payload.from,
                to: action.payload.to,
            };

        default:
            return state;
    }
};

export default StateWiseSalaryReducers;
