import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmployerAction from "../../stores/action/employerAction";
import { useForm } from "react-hook-form";

type FormData = {
  id: string;
  gst_no: any;
  gst_img: any;
  pancard_no: any;
  pancard_img: any;
  msme_no: any;
  msme_img: any;
  partnership_no: any;
  partnership_img: any;
  rc_no: any;
  rc_img: any;
  moa_no: any;
  moa_img: any;
  aoa_no: any;
  aoa_img: any;
  coi_no: any;
  coi_img: any;
  trust_no: any;
  trust_img: any;
};

function EmployerKYCModal({
  modal,
  setModal,
  editableData,
  getEmployerList,
}: any) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<any>();
  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    setValue("gst_no", editableData?.gst_no);
    setValue("gst_img", editableData?.gst_img);
    setValue("pancard_no", editableData?.pancard_no);
    setValue("pancard_img", editableData?.pancard_img);
    setValue("msme_no", editableData?.msme_no);
    setValue("msme_img", editableData?.msme_img);
    setValue("partnership_no", editableData?.partnership_no);
    setValue("partnership_img", editableData?.partnership_img);
    setValue("rc_no", editableData?.rc_no);
    setValue("rc_img", editableData?.rc_img);
    setValue("moa_no", editableData?.moa_no);
    setValue("moa_img", editableData?.moa_img);
    setValue("aoa_no", editableData?.aoa_no);
    setValue("aoa_img", editableData?.aoa_img);
    setValue("coi_no", editableData?.coi_no);
    setValue("coi_img", editableData?.coi_img);
    setValue("trust_no", editableData?.trust_no);
    setValue("trust_img", editableData?.trust_img);
  }, [editableData]);

  const formHandleSubmit = async (data: any) => {
    try {
      await dispatch(
        EmployerAction.updateEmployerKYC({
          id: editableData.id,
          gst_no: data.gst_no,
          gst_img: data.gst_img ? data.gst_img[0] : null,
          pancard_no: data.pancard_no,
          pancard_img: data.pancard_img ? data.pancard_img[0] : null,
          msme_no: data.msme_no,
          msme_img: data.msme_img ? data.msme_img[0] : null,
          partnership_no: data.partnership_no,
          partnership_img: data.partnership_img
            ? data.partnership_img[0]
            : null,
          rc_no: data.rc_no,
          rc_img: data.rc_img ? data.rc_img[0] : null,
          moa_no: data.moa_no,
          moa_img: data.moa_img ? data.moa_img[0] : null,
          aoa_no: data.aoa_no,
          aoa_img: data.aoa_img ? data.aoa_img[0] : null,
          coi_no: data.coi_no,
          coi_img: data.coi_img ? data.coi_img[0] : null,
          trust_no: data.trust_no,
          trust_img: data.trust_img ? data.trust_img[0] : null,
        })
      );
      reset();
      getEmployerList();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <form
            className="needs-validation"
            onSubmit={handleSubmit(formHandleSubmit)}
            encType="multipart/form-data">
            <div className="modal-header">
              <h5 className="modal-title">Update Employer KYC Details</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}></button>
            </div>
            {editableData?.type_of_company === "Proprietor" ? (
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pancard_no", { required: true })}
                        placeholder="PAN Number"
                      />
                      {errors.pancard_no &&
                        errors.pancard_no.type === "required" && (
                          <span className="text-danger">
                            PAN Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload PAN Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("pancard_img", { required: true })}
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            PAN Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        GST Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gst_no", {
                          required: true,
                        })}
                        placeholder="GST Number"
                      />
                      {errors.gst_no && errors.gst_no.type === "required" && (
                        <span className="text-danger">
                          GST Number is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload GST Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("gst_img", {
                          required: true,
                        })}
                      />
                      {errors.gst_img && errors.gst_img.type === "required" && (
                        <span className="text-danger">
                          GST Image is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MSME Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("msme_no")}
                        placeholder="MSME Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MSME Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("msme_img")}></input>
                    </div>
                  </div>
                </div>
              </div>
            ) : editableData?.type_of_company === "Partnership Firm" ? (
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pancard_no", { required: true })}
                        placeholder="PAN Number"
                      />
                      {errors.pancard_no &&
                        errors.pancard_no.type === "required" && (
                          <span className="text-danger">
                            PAN Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload PAN Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("pancard_img", { required: true })}
                        className="form-control"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            PAN Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        GST Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gst_no", {
                          required: true,
                        })}
                        placeholder="GST Number"
                      />
                      {errors.gst_no && errors.gst_no.type === "required" && (
                        <span className="text-danger">
                          GST Number is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload GST Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("gst_img", {
                          required: true,
                        })}
                      />
                      {errors.gst_img && errors.gst_img.type === "required" && (
                        <span className="text-danger">
                          GST Image is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Partnership Deed Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("partnership_no")}
                        placeholder="Partnership Deed Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Partnership Deed Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("partnership_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MSME Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("msme_no")}
                        placeholder="MSME Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MSME Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("msme_img")}></input>
                    </div>
                  </div>
                </div>
              </div>
            ) : editableData?.type_of_company === "LLP" ? (
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pancard_no", { required: true })}
                        placeholder="PAN Number"
                      />
                      {errors.pancard_no &&
                        errors.pancard_no.type === "required" && (
                          <span className="text-danger">
                            PAN Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload PAN Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("pancard_img", { required: true })}
                        className="form-control"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            PAN Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        GST Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gst_no", {
                          required: true,
                        })}
                        placeholder="GST Number"
                      />
                      {errors.gst_no && errors.gst_no.type === "required" && (
                        <span className="text-danger">
                          GST Number is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload GST Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("gst_img", {
                          required: true,
                        })}
                      />
                      {errors.gst_img && errors.gst_img.type === "required" && (
                        <span className="text-danger">
                          GST Image is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        RC Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("rc_no")}
                        placeholder="RC Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload RC Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("rc_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Partnership Deed Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("partnership_no")}
                        placeholder="Partnership Deed Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Partnership Deed Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("partnership_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MSME Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("msme_no")}
                        placeholder="MSME Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MSME Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("msme_img")}></input>
                    </div>
                  </div>
                </div>
              </div>
            ) : editableData?.type_of_company === "Company" ? (
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pancard_no", { required: true })}
                        placeholder="PAN Number"
                      />
                      {errors.pancard_no &&
                        errors.pancard_no.type === "required" && (
                          <span className="text-danger">
                            PAN Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload PAN Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("pancard_img", { required: true })}
                        className="form-control"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            PAN Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        GST Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gst_no", {
                          required: true,
                        })}
                        placeholder="GST Number"
                      />
                      {errors.gst_no && errors.gst_no.type === "required" && (
                        <span className="text-danger">
                          GST Number is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload GST Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("gst_img", {
                          required: true,
                        })}
                      />
                      {errors.gst_img && errors.gst_img.type === "required" && (
                        <span className="text-danger">
                          GST Image is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MOA Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("moa_no")}
                        placeholder="MOA Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MOA Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("moa_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        AOA Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("aoa_no")}
                        placeholder="AOA Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload AOA Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("aoa_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        COI Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("coi_no")}
                        placeholder="COI Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload COI Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("coi_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MSME Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("msme_no")}
                        placeholder="MSME Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MSME Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("msme_img")}></input>
                    </div>
                  </div>
                </div>
              </div>
            ) : editableData?.type_of_company === "Any other entity" ? (
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pancard_no", { required: true })}
                        placeholder="PAN Number"
                      />
                      {errors.pancard_no &&
                        errors.pancard_no.type === "required" && (
                          <span className="text-danger">
                            PAN Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload PAN Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("pancard_img", { required: true })}
                        className="form-control"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            PAN Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        GST Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gst_no", {
                          required: true,
                        })}
                        placeholder="GST Number"
                      />
                      {errors.gst_no && errors.gst_no.type === "required" && (
                        <span className="text-danger">
                          GST Number is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload GST Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("gst_img", {
                          required: true,
                        })}
                      />
                      {errors.gst_img && errors.gst_img.type === "required" && (
                        <span className="text-danger">
                          GST Image is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        COI Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("coi_no")}
                        placeholder="COI Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload COI Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("coi_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MSME Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("msme_no")}
                        placeholder="MSME Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MSME Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("msme_img")}></input>
                    </div>
                  </div>
                </div>
              </div>
            ) : editableData?.type_of_company === "Trust" ? (
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pancard_no", { required: true })}
                        placeholder="PAN Number"
                      />
                      {errors.pancard_no &&
                        errors.pancard_no.type === "required" && (
                          <span className="text-danger">
                            PAN Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload PAN Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("pancard_img", { required: true })}
                        className="form-control"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            PAN Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Trust Deed Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("trust_no", { required: true })}
                        placeholder="Trust Deed Number"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            Trust Deed Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload Trust Deed Image{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("trust_img", { required: true })}
                        className="form-control"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            Trust Deed Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        GST Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gst_no")}
                        placeholder="GST Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload GST Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("gst_img")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MSME Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("msme_no")}
                        placeholder="MSME Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MSME Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("msme_img")}></input>
                    </div>
                  </div>
                </div>
              </div>
            ) : editableData?.type_of_company === "Society" ? (
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pancard_no", { required: true })}
                        placeholder="PAN Number"
                      />
                      {errors.pancard_no &&
                        errors.pancard_no.type === "required" && (
                          <span className="text-danger">
                            PAN Number is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload PAN Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("pancard_img", { required: true })}
                        className="form-control"
                      />
                      {errors.pancard_img &&
                        errors.pancard_img.type === "required" && (
                          <span className="text-danger">
                            PAN Image is required
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        RC Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("rc_no", { required: true })}
                        placeholder="RC Number"
                      />
                      {errors.rc_no && errors.rc_no.type === "required" && (
                        <span className="text-danger">
                          RC Number is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload RC Image <span className="text-danger">*</span>
                      </label>
                      <input
                        type="file"
                        {...register("rc_img", { required: true })}
                        className="form-control"
                      />
                      {errors.rc_img && errors.rc_img.type === "required" && (
                        <span className="text-danger">
                          RC Image is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MOA Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("moa_no")}
                        placeholder="MOA Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MOA Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        {...register("moa_img")}
                        className="form-control"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        GST Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("gst_no")}
                        placeholder="GST Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload GST Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("gst_img")}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        MSME Number{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("msme_no")}
                        placeholder="MSME Number"></input>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>
                        Upload MSME Image{" "}
                        <span className="text-success">(Optional)</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        {...register("msme_img")}></input>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={closeModal}>
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4">
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Update KYC Details
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EmployerKYCModal;
