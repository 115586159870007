import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import StateWiseSalaryAction from "../stores/action/stateWiseSalaryAction";
import {RootState} from "../stores";
import WebAction from "../stores/action/webAction";

type FormData = {
    state: string;
    amount: string;
};

function AddStateWiseSalary({
                                setStateWiseSalaryModal,
                                stateWiseSalaryModal,
                                type,
                                editableData,
                                getData,
                            }: any) {
    const handleClose = () => setStateWiseSalaryModal(false);
    const dispatch = useDispatch<any>();
    const {getStateList} = useSelector((state: RootState) => state.web);
    const [loading, setLoading] = useState(false);

    const getStateData = useCallback(async () => {
        await dispatch(WebAction.getState());
    }, [dispatch]);


    useEffect(() => {
        getStateData();
    }, [getStateData]);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
    } = useForm<FormData>();

    useEffect(() => {
        if (type === "update" && editableData) {
            setValue("amount", editableData.amount);
        } else {
            setValue("amount", "");
            setValue("state", "");
        }
    }, [type, editableData, stateWiseSalaryModal, setValue]);

    const formHandleSubmit = async (data: any) => {
        setLoading(true);
        try {
            if (type === "add") {
                await dispatch(
                    StateWiseSalaryAction.addStateWiseSalary({
                        amount: data.amount,
                        state: data.state,
                    })
                );
            } else {
                await dispatch(
                    StateWiseSalaryAction.updateStateWiseSalary({
                        id: editableData.id,
                        amount: data.amount
                    })
                );
            }
            setLoading(false)
            reset()
            getData()
            setStateWiseSalaryModal(false)
        } catch (error) {
            handleClose();
            setLoading(false);
        }
    };

    return (
        <div className="modal" style={{display: stateWiseSalaryModal ? "block" : "none"}}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form
                        onSubmit={handleSubmit(formHandleSubmit)}
                        className="needs-validation"
                        encType="multipart/form-data">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {type === "add" ? "Add" : "Update"} State Wise Salary
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className={"row"}>
                                {
                                    type === "add" ? <div className={"col-lg-6"}>
                                        <div className="form-group">
                                            <select
                                                {...register("state")}
                                                className="form-control">
                                                <option value="" disabled>
                                                    Select State
                                                </option>
                                                {getStateList &&
                                                    getStateList?.map((stateData: any) => {
                                                        return (
                                                            <option key={stateData.id} value={stateData.id}>
                                                                {stateData.name}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div> : null
                                }
                                <div className={type === "add" ? "col-lg-6" : "col-lg-12"}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("amount")}
                                            placeholder="Amount"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-danger light"
                                data-bs-dismiss="modal"
                                disabled={loading}
                                onClick={handleClose}>
                                Close
                            </button>
                            <button
                                type="submit"
                                disabled={loading}
                                className="btn btn-primary px-4">
                                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                                &nbsp; {type === "add" ? "Save" : "Update"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddStateWiseSalary;
