import ErmType from "../type/ermType";

export interface RegistrationAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  ermList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  totalPendingKYC: 0,
  totalSendForApprovalKYC: 0,
  totalApprovedKYC: 0,
  totalRejectedKYC: 0,
  ermDetails: {},
};

const ErmReducers = (state = INITIAL_STATE, action: RegistrationAction) => {
  switch (action.type) {
    case ErmType.SET_ERM_LIST:
      return {
        ...state,
        ermList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
        totalPendingKYC: action.payload.totalPendingKYC,
        totalSendForApprovalKYC: action.payload.totalSendForApprovalKYC,
        totalApprovedKYC: action.payload.totalApprovedKYC,
        totalRejectedKYC: action.payload.totalRejectedKYC,
      };

    case ErmType.UPDATE_ERM_KYC:
      return {
        ...state,
      };

    case ErmType.UPDATE_BANK_DETAILS:
      return {
        ...state,
      };

    case ErmType.GET_ERM_DETAILS:
      return {
        ...state,
        ermDetails: action.payload,
      };

    case ErmType.ACCOUNT_STATUS: {
      return {
        ...state,
      };
    }

    case ErmType.KYC_APPROVAL: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default ErmReducers;
