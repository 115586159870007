import { useCallback, useEffect } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import EmployerAction from "../../stores/action/employerAction";
import { useParams } from "react-router-dom";
import { RootState } from "../../stores";

function EmployerDetails() {
  const dispatch = useDispatch<any>();
  const { id } = useParams<any>();
  const { employerDetails } = useSelector((state: RootState) => state.employer);
  const getEmployerData = useCallback(async () => {
    await dispatch(
      EmployerAction.getEmployerDetails({
        id: Number(id),
      }),
    );
  }, [dispatch, id]);

  useEffect(() => {
    getEmployerData();
  }, [getEmployerData]);

  return (
    <WebLayout pageName={"EmployerDetails"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Employer Details</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <a href="../../index.html">
                      <i className="fas fa-home"></i> Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Employer Details</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="profile-tab-box">
                <div className="p-l-20">
                  <ul className="nav ">
                    <li className="nav-item tab-all">
                      <a
                        className="nav-link active show"
                        href="#project"
                        data-bs-toggle="tab"
                      >
                        Employer Profile
                      </a>
                    </li>
                    <li className="nav-item tab-all p-l-20">
                      <a
                        className="nav-link"
                        href="#kycDocument"
                        data-bs-toggle="tab"
                      >
                        Employer KYC
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className="tab-pane active"
                  id="project"
                  aria-expanded="true"
                >
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="card project_widget mt-0">
                        <div className="body">
                          <div className="row ">
                            <table className="table-bordered">
                              <thead></thead>
                              <tbody className="employer__profile">
                                <tr>
                                  <th>EMP Number</th>
                                  <td>{employerDetails.emp_no}</td>
                                </tr>
                                <tr>
                                  <th>Type of Company</th>
                                  <td>{employerDetails.type_of_company}</td>
                                </tr>
                                <tr>
                                  <th>Company Name</th>
                                  <td>{employerDetails.name}</td>
                                </tr>
                                <tr>
                                  <th>Email Id</th>
                                  <td>{employerDetails.email}</td>
                                </tr>
                                <tr>
                                  <th>Mobile Number</th>
                                  <td>{employerDetails.mobile}</td>
                                </tr>
                                <tr>
                                  <th>State</th>
                                  <td>{employerDetails.sName}</td>
                                </tr>
                                <tr>
                                  <th>District</th>
                                  <td>{employerDetails.dName}</td>
                                </tr>
                                <tr>
                                  <th>Pin Code</th>
                                  <td>{employerDetails.pincode}</td>
                                </tr>
                                <tr>
                                  <th>Address</th>
                                  <td>{employerDetails.address}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="tabpanel"
                  className="tab-pane"
                  id="timeline"
                  aria-expanded="false"
                ></div>

                <div
                  role="tabpanel"
                  className="tab-pane"
                  id="kycDocument"
                  aria-expanded="false"
                >
                  <div className="card">
                    {employerDetails?.type_of_company === "Proprietor" ? (
                      <div className="body">
                        <div className="row">
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.gst_img}
                                  alt=""
                                />
                                <span className="project-title">GST</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              GST No. {employerDetails.gst_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.pancard_img}
                                  alt=""
                                />
                                <span className="project-title">PAN </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              PAN No. {employerDetails.pancard_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.msme_img}
                                  alt=""
                                />
                                <span className="project-title">MSME</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MSME No. {employerDetails.msme_no}
                            </h5>
                          </article>
                        </div>
                      </div>
                    ) : employerDetails?.type_of_company ===
                      "Partnership Firm" ? (
                      <div className="body">
                        <div className="row">
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.pancard_img}
                                  alt=""
                                />
                                <span className="project-title">PAN </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              PAN No. {employerDetails.pancard_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.gst_img}
                                  alt=""
                                />
                                <span className="project-title">GST</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              GST No. {employerDetails.gst_no}
                            </h5>
                          </article>

                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.partnership_img}
                                  alt=""
                                />
                                <span className="project-title">
                                  Partnership Deed
                                </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              Partnership Deed No.{" "}
                              {employerDetails.partnership_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.msme_img}
                                  alt=""
                                />
                                <span className="project-title">MSME</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MSME No. {employerDetails.msme_no}
                            </h5>
                          </article>
                        </div>
                      </div>
                    ) : employerDetails?.type_of_company === "LLP" ? (
                      <div className="body">
                        <div className="row">
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.pancard_img}
                                  alt=""
                                />
                                <span className="project-title">PAN </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              PAN No. {employerDetails.pancard_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.gst_img}
                                  alt=""
                                />
                                <span className="project-title">GST</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              GST No. {employerDetails.gst_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.rc_img}
                                  alt=""
                                />
                                <span className="project-title">RC</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              RC No. {employerDetails.rc_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.partnership_img}
                                  alt=""
                                />
                                <span className="project-title">
                                  Partnership Deed
                                </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              Partnership Deed No.{" "}
                              {employerDetails.partnership_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.msme_img}
                                  alt=""
                                />
                                <span className="project-title">MSME</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MSME No. {employerDetails.msme_no}
                            </h5>
                          </article>
                        </div>
                      </div>
                    ) : employerDetails?.type_of_company === "Company" ? (
                      <div className="body">
                        <div className="row">
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.pancard_img}
                                  alt=""
                                />
                                <span className="project-title">PAN </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              PAN No. {employerDetails.pancard_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.gst_img}
                                  alt=""
                                />
                                <span className="project-title">GST</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              GST No. {employerDetails.gst_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.moa_img}
                                  alt=""
                                />
                                <span className="project-title">MOA </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MOA No. {employerDetails.moa_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.aoa_img}
                                  alt=""
                                />
                                <span className="project-title">AOA </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              AOA No. {employerDetails.aoa_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.coi_img}
                                  alt=""
                                />
                                <span className="project-title">COI </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              COI No. {employerDetails.coi_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.msme_img}
                                  alt=""
                                />
                                <span className="project-title">MSME</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MSME No. {employerDetails.msme_no}
                            </h5>
                          </article>
                        </div>
                      </div>
                    ) : employerDetails?.type_of_company ===
                      "Any other entity" ? (
                      <div className="body">
                        <div className="row">
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.pancard_img}
                                  alt=""
                                />
                                <span className="project-title">PAN </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              PAN No. {employerDetails.pancard_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.gst_img}
                                  alt=""
                                />
                                <span className="project-title">GST</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              GST No. {employerDetails.gst_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.coi_img}
                                  alt=""
                                />
                                <span className="project-title">COI </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              COI No. {employerDetails.coi_no}
                            </h5>
                          </article>

                          <article className="col-md-4 col-sm-6 portfolio-item web-design psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.msme_img}
                                  alt=""
                                />
                                <span className="project-title">MSME</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MSME No. {employerDetails.msme_no}
                            </h5>
                          </article>
                        </div>
                      </div>
                    ) : employerDetails?.type_of_company === "Trust" ? (
                      <div className="body">
                        <div className="row">
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.pancard_img}
                                  alt=""
                                />
                                <span className="project-title">PAN </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              PAN No. {employerDetails.pancard_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.trust_img}
                                  alt=""
                                />
                                <span className="project-title">
                                  Trust Deed{" "}
                                </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              Trust Deed No. {employerDetails.trust_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.gst_img}
                                  alt=""
                                />
                                <span className="project-title">GST</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              GST No. {employerDetails.gst_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.msme_img}
                                  alt=""
                                />
                                <span className="project-title">MSME</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MSME No. {employerDetails.msme_no}
                            </h5>
                          </article>
                        </div>
                      </div>
                    ) : employerDetails?.type_of_company === "Society" ? (
                      <div className="body">
                        <div className="row">
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.pancard_img}
                                  alt=""
                                />
                                <span className="project-title">PAN</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              PAN No. {employerDetails.pancard_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.rc_img}
                                  alt=""
                                />
                                <span className="project-title">RC </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              RC No. {employerDetails.rc_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item apps">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.moa_img}
                                  alt=""
                                />
                                <span className="project-title">MOA </span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MOA No. {employerDetails.moa_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design apps psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.gst_img}
                                  alt=""
                                />
                                <span className="project-title">GST</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              GST No. {employerDetails.gst_no}
                            </h5>
                          </article>
                          <article className="col-md-4 col-sm-6 portfolio-item web-design psd">
                            <div className="portfolio-thumb in">
                              <a href="#" className="main-link">
                                <img
                                  className="img-responsive img-center"
                                  src={employerDetails.msme_img}
                                  alt=""
                                />
                                <span className="project-title">MSME</span>
                                <span className="overlay-mask"></span>
                              </a>
                            </div>
                            <h5 className="text-center mt-2">
                              MSME No. {employerDetails.msme_no}
                            </h5>
                          </article>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployerDetails;
