import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import toast from "react-hot-toast";
import ErmType, {
  GetERM,
  GetERMDetails,
  UpdateERMBankDetails,
  UpdateERMKYC,
  updateERMKYCApproval,
  UpdateERMPanelStatus,
} from "../type/ermType";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const ErmAction = {
  getERMList:
    (data: GetERM): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "erm/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            type: data.type,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        dispatch({
          type: ErmType.SET_ERM_LIST,
          payload: {
            data: result.data.data.list.data,
            currentPage: result.data.data.list.current_page,
            lastPage: result.data.data.list.last_page,
            totalData: result.data.data.list.total,
            from: result.data.data.list.from,
            to: result.data.data.list.to,
            totalPendingKYC: result.data.data.totalPendingKYC,
            totalSendForApprovalKYC: result.data.data.totalSendForApprovalKYC,
            totalApprovedKYC: result.data.data.totalApprovedKYC,
            totalRejectedKYC: result.data.data.totalRejectedKYC,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateERMKYC:
    (data: UpdateERMKYC): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "erm/kyc_update",
          {
            id: data.id,
            pan_image: data.panCardImage,
            aadhaar_front_image: data.aadhaarCardFrontImage,
            aadhaar_back_image: data.aadhaarCardBackImage,
            agreement_image: data.agreementImage,
            panCardNumber: data.panCardNo,
            aadhaarCardNumber: data.aadhaarCardNo,
            kycStatus: data.kycStatus,
            kycReason: data.kycReason,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        dispatch({
          type: ErmType.UPDATE_ERM_KYC,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },

  updateERMBankDetails:
    (data: UpdateERMBankDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "erm/bank_update",
          {
            id: data.id,
            bankName: data.bankName,
            branchName: data.branchName,
            accountNumber: data.accountNumber,
            ifscCode: data.ifscCode,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        dispatch({
          type: ErmType.UPDATE_ERM_KYC,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },

  getERMDetails:
    (data: GetERMDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "erm/details/" + data.id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        dispatch({
          type: ErmType.GET_ERM_DETAILS,
          payload: result.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  updateERMPanelStatus:
    (data: UpdateERMPanelStatus): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const result: any = await axios.post(
          BASE_URL + "erm/panel",
          {
            id: data.id,
            status: data.status,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        dispatch({
          type: ErmType.ACCOUNT_STATUS,
        });

        if (result.data.status === true) {
          if (data.status === "ACTIVE") {
            toast.success(result.data.msg);
          } else {
            toast.error(result.data.msg);
          }
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  updateERMKYCApproval:
    (data: updateERMKYCApproval): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const result: any = await axios.post(
          BASE_URL + "erm/kyc_approval",
          {
            id: data.id,
            status: data.status,
            reason: data.reason,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
            },
          },
        );

        dispatch({
          type: ErmType.KYC_APPROVAL,
        });

        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
};

export default ErmAction;
