import {ThunkAction} from "redux-thunk";
import {RootState} from "..";
import {ADMIN_TOKEN, BASE_URL} from "../../utils/Constants";
import axios from "axios";
import toast from "react-hot-toast";
import StateWiseSalaryType, {
    AddStateWiseSalaryType,
    GetStateWiseSalaryType,
    UpdateStateWiseSalaryType
} from "../type/stateWiseSalaryType";
import LogoutAction from "./LogoutAction";

interface Action {
    type: string;
    payload?: any;
    token?: any;
}

const StateWiseSalaryAction = {

    addStateWiseSalary:
        (data: AddStateWiseSalaryType): ThunkAction<void, RootState, null, Action> =>
            async (dispatch) => {
                try {
                    const token: string | any = localStorage.getItem(ADMIN_TOKEN);
                    const result: any = await axios.post(
                        BASE_URL + "state_wise_salary/store",
                        {
                            state: data.state,
                            amount: data.amount,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + JSON.parse(token),
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    dispatch({
                        type: StateWiseSalaryType.ADD_STATE_WISE_SALARY,
                    });
                    toast.success(result.data.msg);
                } catch (error: any) {
                    if (error.response.status === 401) {
                        dispatch(LogoutAction.logout());
                    }
                }
            },

    getStateWiseSalary:
        (data: GetStateWiseSalaryType): ThunkAction<void, RootState, null, Action> =>
            async (dispatch) => {
                try {
                    const token: string | any = localStorage.getItem(ADMIN_TOKEN);
                    const result: any = await axios.post(
                        BASE_URL + "state_wise_salary/list",
                        {
                            search: data.search,
                            limit: data.limit,
                            page: data.page,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + JSON.parse(token),
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    dispatch({
                        type: StateWiseSalaryType.GET_STATE_WISE_SALARY_LIST,
                        payload: {
                            data: result.data.data.data,
                            currentPage: result.data.data.current_page,
                            lastPage: result.data.data.last_page,
                            totalData: result.data.data.total,
                            from: result.data.data.from,
                            to: result.data.data.to,
                        },
                    });
                } catch (error: any) {
                    if (error.response.status === 401) {
                        dispatch(LogoutAction.logout());
                    }
                }
            },

    updateStateWiseSalary:
        (data: UpdateStateWiseSalaryType): ThunkAction<void, RootState, null, Action> =>
            async (dispatch) => {
                try {
                    const token: string | any = localStorage.getItem(ADMIN_TOKEN);
                    const result: any = await axios.post(
                        BASE_URL + "state_wise_salary/update",
                        {
                            id: data.id,
                            amount: data.amount,
                        },
                        {
                            headers: {
                                Authorization: "Bearer " + JSON.parse(token),
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    dispatch({
                        type: StateWiseSalaryType.UPDATE_STATE_WISE_SALARY,
                    });
                    toast.success(result.data.msg);
                } catch (error: any) {
                    if (error.response.status === 401) {
                        dispatch(LogoutAction.logout());
                    }
                }
            },

};

export default StateWiseSalaryAction;
