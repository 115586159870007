import WebType from "../type/webType";

interface WebAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  getAbout: "",
  getCertificationList: [],
  getDocumentList: [],
  getStateList: [],
  getDistrictList: [],
  getCompanyTypeList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const WebReducers = (state = INITIAL_STATE, action: WebAction) => {
  switch (action.type) {
    case WebType.SET_ADD_ABOUT:
      return {
        ...state,
      };

    case WebType.SET_ABOUT_US:
      return {
        ...state,
        getAbout: action.payload.data,
      };

    case WebType.ADD_CERTIFICATION:
      return {
        ...state,
      };

    case WebType.SET_CERTIFICATION_LIST:
      return {
        ...state,
        getCertificationList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case WebType.UPDATE_CERTIFICATION:
      return {
        ...state,
      };

    case WebType.DELETE_CERTIFICATION:
      return {
        ...state,
      };

    case WebType.ADD_DOCUMENT:
      return {
        ...state,
      };

    case WebType.SET_DOCUMENT_LIST:
      return {
        ...state,
        getDocumentList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case WebType.UPDATE_DOCUMENT:
      return {
        ...state,
      };

    case WebType.DELETE_DOCUMENT:
      return {
        ...state,
      };

    case WebType.GET_STATE_LIST:
      return {
        ...state,
        getStateList: action.payload.data,
      };

    case WebType.GET_DISTRICT_LIST:
      return {
        ...state,
        getDistrictList: action.payload.data,
      };

    case WebType.ADD_COMPANY_TYPE:
      return {
        ...state,
      };

    case WebType.SET_COMPANY_TYPE_LIST:
      return {
        ...state,
        getCompanyTypeList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case WebType.UPDATE_COMPANY_TYPE:
      return {
        ...state,
      };

    case WebType.DELETE_COMPANY_TYPE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default WebReducers;
