import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";

import AuthReducer from "./reducers/authReducers";
import RegistrationReducers from "./reducers/registrationReducers";
import WebReducers from "./reducers/webReducers";
import EnquiryReducers from "./reducers/enquiryReducers";
import EmployerReducers from "./reducers/employerReducers";
import EmployeeReducers from "./reducers/employeeReducers";
import AttendanceReducers from "./reducers/attendanceReducers";
import StateWiseSalaryReducers from "./reducers/stateWiseSalaryReducers";
import ERMReducers from "./reducers/ermReducers";

const rootReducer = combineReducers({
  auth: AuthReducer,
  enquiry: EnquiryReducers,
  registration: RegistrationReducers,
  web: WebReducers,
  employer: EmployerReducers,
  employee: EmployeeReducers,
  attendance: AttendanceReducers,
  stateWiseSalary: StateWiseSalaryReducers,
  erm: ERMReducers,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
