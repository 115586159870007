import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import { useState } from "react";

function MemberList() {
  function menuCheck() {
    return false;
  }

  return (
    <WebLayout pageName={"Home"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Member List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">Member List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>
                    <strong>All</strong> Member
                  </h2>
                  <ul className="header-dropdown m-r--5">
                    <li className="dropdown">
                      <Link
                        to="#"
                        onClick={menuCheck}
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <ul className="dropdown-menu float-end">
                        <li>
                          <Link to="#" onClick={menuCheck}>
                            Action
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={menuCheck}>
                            Another action
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={menuCheck}>
                            Something else here
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center"> Name </th>
                          <th className="center"> Designation </th>
                          <th className="center"> Mobile </th>
                          <th className="center"> Email </th>
                          <th className="center"> Address </th>
                          <th className="center">Joining Date</th>
                          <th className="center"> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user1.jpg" alt="" />
                          </td>
                          <td className="center">Rajesh</td>
                          <td className="center">Programmer</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">22 Feb 2000</td>
                          <td className="center">
                            <a
                              href="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </a>
                            <a href="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </a>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user2.jpg" alt="" />
                          </td>
                          <td className="center">Pooja Patel</td>
                          <td className="center">Manager</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">27 Aug 2005</td>
                          <td className="center">
                            <a
                              href="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </a>
                            <a href="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </a>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user3.jpg" alt="" />
                          </td>
                          <td className="center">Sarah Smith</td>
                          <td className="center">Manager</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">05 Jun 2011</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user4.jpg" alt="" />
                          </td>
                          <td className="center">John Deo</td>
                          <td className="center">Designer</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">15 Feb 2012</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user5.jpg" alt="" />
                          </td>
                          <td className="center">Jay Soni</td>
                          <td className="center">Purchase Officer</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">12 Nov 2012</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user6.jpg" alt="" />
                          </td>
                          <td className="center">Jacob Ryan</td>
                          <td className="center">Receptionist</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">03 Dec 2001</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user7.jpg" alt="" />
                          </td>
                          <td className="center">Megha Trivedi</td>
                          <td className="center">Manager</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">17 Mar 2013</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user8.jpg" alt="" />
                          </td>
                          <td className="center">Rajesh</td>
                          <td className="center">Sr. Tester</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">22 Feb 2000</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user9.jpg" alt="" />
                          </td>
                          <td className="center">Pooja Patel</td>
                          <td className="center">Team Leader</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">27 Aug 2005</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user6.jpg" alt="" />
                          </td>
                          <td className="center">Sarah Smith</td>
                          <td className="center">Manager</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">05 Jun 2011</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user5.jpg" alt="" />
                          </td>
                          <td className="center">Jacob Ryan</td>
                          <td className="center">Receptionist</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">03 Dec 2001</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user4.jpg" alt="" />
                          </td>
                          <td className="center">Megha Trivedi</td>
                          <td className="center">Manager</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">17 Mar 2013</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user3.jpg" alt="" />
                          </td>
                          <td className="center">Rajesh</td>
                          <td className="center">Director</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">22 Feb 2000</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user2.jpg" alt="" />
                          </td>
                          <td className="center">Pooja Patel</td>
                          <td className="center">Programmer</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">27 Aug 2005</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                        <tr className="odd gradeX">
                          <td className="table-img center">
                            <img src="assets/images/user/user1.jpg" alt="" />
                          </td>
                          <td className="center">Sarah Smith</td>
                          <td className="center">Manager</td>
                          <td className="center">+ 167-894-2378</td>
                          <td className="center">example@email.com</td>
                          <td className="center">22,tilak appt. surat</td>
                          <td className="center">05 Jun 2011</td>
                          <td className="center">
                            <Link
                              to="edit-employee.html"
                              className="btn btn-tbl-edit"
                            >
                              <i className="material-icons">create</i>
                            </Link>
                            <Link to="" className="btn btn-tbl-delete">
                              <i className="material-icons">delete_forever</i>
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th className="center">#</th>
                          <th className="center"> Name </th>
                          <th className="center"> Designation </th>
                          <th className="center"> Mobile </th>
                          <th className="center"> Email </th>
                          <th className="center"> Address </th>
                          <th className="center">Joining Date</th>
                          <th className="center"> Action </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default MemberList;
