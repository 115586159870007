import { useState } from "react";
import { useDispatch } from "react-redux";
import ErmAction from "../../stores/action/ermAction";

function ERMKYCApprovalModal({
  modal,
  setModal,
  editableData,
  getERMList,
}: any) {
  const dispatch = useDispatch<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<any>("");
  const [reason, setReason] = useState<any>("");

  const closeModal = () => {
    setModal(false);
  };

  const formHandleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        ErmAction.updateERMKYCApproval({
          id: editableData.id,
          status: status,
          reason: reason,
        }),
      );
      getERMList();
      closeModal();
      setStatus("");
      setReason("");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <form
            className="needs-validation"
            onSubmit={formHandleSubmit}
            encType="multipart/form-data"
          >
            <div className="modal-header">
              <h5 className="modal-title">KYC Approval</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className={"form-group"}>
                  <label htmlFor="id">Action</label>
                  <select
                    name="approve"
                    id=""
                    className={"form-control"}
                    required
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="" selected disabled>
                      Select{" "}
                    </option>
                    <option value="APPROVED">Approved</option>
                    <option value="REJECTED">Reject</option>
                  </select>
                </div>

                <div className={"form-group"}>
                  <label htmlFor="">Reason</label>
                  <input
                    type="text"
                    name={"reason"}
                    className={"form-control"}
                    placeholder={"Reason"}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ERMKYCApprovalModal;
