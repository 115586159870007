import { useCallback, useEffect, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { Link } from "react-router-dom";
import MyEditor from "../../components/MyEditor";
import WebAction from "../../stores/action/webAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "../../stores";

function AboutUs() {
  const { getAbout } = useSelector((state: RootState) => state.web);
  const [editorData, setEditorData] = useState<string>("");
  const [title, setTitle] = useState<any>("");
  const [meta, setMeta] = useState<any>("");
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const handleEditorChange = (newData: string) => {
    setEditorData(newData);
  };

  const formHandleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        WebAction.addAbout({
          title: title,
          meta: meta,
          content: editorData,
        }),
      );
      setIsLoader(false);
    } catch (error: any) {
      setIsLoader(false);
      toast.error(error.message);
    }
  };

  const getAboutData = useCallback(async () => {
    await dispatch(WebAction.getAboutUs());
  }, [dispatch]);

  useEffect(() => {
    getAboutData();
  }, [getAboutData]);

  useEffect(() => {
    setTitle(getAbout?.title);
    setMeta(getAbout?.meta);
    if (getAbout?.content) setEditorData(getAbout?.content);
  }, [getAbout?.title, getAbout?.meta, getAbout?.content]);

  return (
    <WebLayout pageName={"AboutUs"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">About Us</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">About Us</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={formHandleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={meta}
                            onChange={(e) => setMeta(e.target.value)}
                            placeholder="Meta"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <MyEditor
                            initialValue={editorData}
                            onChange={handleEditorChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isLoader}
                        >
                          {isLoader ? (
                            <i className={"fa fa-spinner fa-spin"}></i>
                          ) : (
                            ""
                          )}
                          &nbsp; Save About
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default AboutUs;
