import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EnquiryAction from "../../stores/action/enquiryAction";
import EmployerAction from "../../stores/action/employerAction";
import toast from "react-hot-toast";

function EnquiryList() {
  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [catModal, setCatModal] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const {
    enquiryList,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
    totalEnquiry,
    todayEnquiry,
  } = useSelector((state: RootState) => state.enquiry);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEnquiryList = useCallback(async () => {
    await dispatch(
      EnquiryAction.getEnquiry({
        limit: limit,
        search: search,
        page: page,
      }),
    );
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getEnquiryList();
  }, [getEnquiryList, search, limit, page]);

  const modalOpen = (id: number) => {
    setSelectedId(id);
    setCatModal(true);
  };
  const handleClose = () => setCatModal(false);

  const createEmployerAccount = async () => {
    try {
      if (selectedId) {
        const result = await dispatch(
          EmployerAction.createEmployer({ id: selectedId }),
        );
        getEnquiryList();
      }
      handleClose();
    } catch (error) {
      toast.error("Something went wrong while creating the employer account.");
    }
  };

  return (
    <WebLayout pageName={"Home"}>
      <section className="content">
        <div className="modal" style={{ display: catModal ? "block" : "none" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Registration</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                Are you sure you want to register this enquiry as an employer?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={createEmployerAccount}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Enquiry List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Enquiry List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div
                  className="header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2>
                    <strong>All</strong> Enquiry
                  </h2>
                  <div>
                    <button
                      className="btn bg-orange btn-lg btn-block waves-effect"
                      type="button"
                    >
                      <i className="fa fa-envelope"></i> Total Enquiry
                      <span className="badge">{totalEnquiry}</span>
                    </button>{" "}
                    &nbsp;&nbsp;
                    <button
                      className="btn bg-orange btn-lg btn-block waves-effect"
                      type="button"
                    >
                      <i className="fa fa-envelope"></i> Today Enquiry
                      <span className="badge">{todayEnquiry}</span>
                    </button>{" "}
                  </div>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
                >
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => set_limit(e.target.value)}
                      id="defaultSelect"
                      className="form-select"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => set_search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center"> Date</th>
                          <th className="center"> Name </th>
                          <th className="center"> Mobile </th>
                          <th className="center"> Email </th>
                          <th className="center"> State</th>
                          <th className="center"> District</th>
                          <th className="center"> Pin Code</th>
                          <th className="center"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enquiryList?.length > 0 ? (
                          enquiryList.map((dataList: any, index: number) => {
                            const formatDate = (dateString: string) => {
                              const date = new Date(dateString);
                              return date.toLocaleDateString("en-GB");
                            };
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{formatDate(dataList.date)}</td>
                                <td>{dataList.empName}</td>
                                <td>{dataList.mobile}</td>
                                <td>{dataList.email}</td>
                                <td>{dataList.sName}</td>
                                <td>{dataList.dName}</td>
                                <td>{dataList.pincode}</td>
                                <th>
                                  {dataList.status === 0 ? (
                                    <a
                                      href="#"
                                      onClick={() => modalOpen(dataList.id)}
                                      className="btn btn-success"
                                    >
                                      REG
                                    </a>
                                  ) : (
                                    <a href="#" className="btn btn-danger">
                                      Done
                                    </a>
                                  )}
                                </th>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {enquiryList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p) => {
                              return (
                                <li
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}
                                >
                                  <a
                                    className="page-link"
                                    href="#"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0"
                                  >
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }
                            >
                              <a
                                href="#"
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}
                            >
                              <a
                                href="#"
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EnquiryList;
