import { Link } from "react-router-dom";
import ERMKYCApprovalModal from "./ERMKYCApprovalModal";
import React, { useState } from "react";

function ERMDetailsModal({ editableData, getERMList }: any) {
  const [modal, setModal] = useState(false);

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          ERM Details
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <ul className="nav nav-tabs tab-nav-right" role="tablist">
          <li role="presentation">
            <a href="#personal" data-bs-toggle="tab" className="active">
              Personal Details
            </a>
          </li>
          <li role="presentation">
            <a href="#bank" data-bs-toggle="tab">
              Bank Details
            </a>
          </li>
          <li role="presentation">
            <a href="#kyc" data-bs-toggle="tab">
              KYC Details
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            role="tabpanel"
            className="tab-pane active"
            id="personal"
            aria-expanded="true"
          >
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>ERM Id:</th>
                  <td>N/A</td>
                </tr>
                <tr>
                  <th>Name:</th>
                  <td>{editableData?.name || "N/A"}</td>
                </tr>
                <tr>
                  <th>Email:</th>
                  <td>{editableData?.email || "N/A"}</td>
                </tr>
                <tr>
                  <th>Mobile:</th>
                  <td>{editableData?.phone || "N/A"}</td>
                </tr>
                <tr>
                  <th>State:</th>
                  <td>{editableData?.state || "N/A"}</td>
                </tr>
                <tr>
                  <th>District:</th>
                  <td>{editableData?.district || "N/A"}</td>
                </tr>
                <tr>
                  <th>Address:</th>
                  <td>{editableData?.address || "N/A"}</td>
                </tr>
                <tr>
                  <th>Pin Code:</th>
                  <td>{editableData?.pincode || "N/A"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            role="tabpanel"
            className="tab-pane "
            id="bank"
            aria-expanded="true"
          >
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Account Holder Name:</th>
                  <td>{editableData?.account_holder_name || "N/A"}</td>
                </tr>
                <tr>
                  <th>Account Number:</th>
                  <td>{editableData?.account_number || "N/A"}</td>
                </tr>
                <tr>
                  <th>Bank Name:</th>
                  <td>{editableData?.bank_name || "N/A"}</td>
                </tr>
                <tr>
                  <th>IFSC Code:</th>
                  <td>{editableData?.ifsc_code || "N/A"}</td>
                </tr>
                <tr>
                  <th>Branch Name:</th>
                  <td>{editableData?.branch_name || "N/A"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            role="tabpanel"
            className="tab-pane"
            id="kyc"
            aria-expanded="true"
          >
            <table className="table table-bordered">
              <tbody>
                {editableData?.kyc_status === "SEND FOR APPROVAL" ||
                editableData?.kyc_status === "REJECTED" ? (
                  <tr>
                    <th>
                      <button
                        className={"btn btn-primary"}
                        onClick={() => setModal(true)}
                      >
                        KYC ACTION
                      </button>
                    </th>
                  </tr>
                ) : null}
                <tr>
                  <th>
                    {editableData?.agreement_image ? (
                      <>
                        <span>
                          <b>Agreement Image</b>
                        </span>{" "}
                        <br />
                        <Link
                          to={editableData?.agreement_image}
                          target={"_blank"}
                        >
                          <img
                            src={editableData?.agreement_image}
                            className="mb-2"
                            style={{ width: 150, height: 150 }}
                          />
                        </Link>
                        <br />
                      </>
                    ) : null}
                    <span>
                      <b>PAN Number:</b> {editableData?.pan_number || "N/A"}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>
                    {editableData?.pan_image ? (
                      <>
                        <span>
                          <b>PAN Image</b>
                        </span>{" "}
                        <br />
                        <Link to={editableData?.pan_image} target={"_blank"}>
                          <img
                            src={editableData?.pan_image}
                            className="mb-2"
                            style={{ width: 150, height: 150 }}
                          />
                        </Link>
                        <br />
                      </>
                    ) : null}
                    <span>
                      <b>PAN Number:</b> {editableData?.pan_number || "N/A"}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>
                    {editableData?.aadhaar_front_image ? (
                      <>
                        <span>
                          <b>Aadhaar Front Image</b>
                        </span>{" "}
                        <br />
                        <Link
                          to={editableData?.aadhaar_front_image}
                          target={"_blank"}
                        >
                          <img
                            src={editableData?.aadhaar_front_image}
                            className="mb-2"
                            style={{ width: 150, height: 150 }}
                          />
                        </Link>
                        <br />
                      </>
                    ) : null}
                    {editableData?.aadhaar_back_image ? (
                      <>
                        <span>
                          <b>Aadhaar Back Image</b>
                        </span>{" "}
                        <br />
                        <Link
                          to={editableData?.aadhaar_back_image}
                          target={"_blank"}
                        >
                          <img
                            src={editableData?.aadhaar_back_image}
                            className="mb-2"
                            style={{ width: 150, height: 150 }}
                          />
                        </Link>
                        <br />
                      </>
                    ) : null}
                    <span>
                      <b>Aadhaar Number:</b>{" "}
                      {editableData?.aadhaar_card_number || "N/A"}
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ERMKYCApprovalModal
        modal={modal}
        setModal={setModal}
        editableData={editableData}
        getERMList={getERMList}
      />
    </div>
  );
}

export default ERMDetailsModal;
