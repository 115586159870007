import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttendanceAction from "../../stores/action/attendanceAction";
import { RootState } from "../../stores";

function AttendanceList() {
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch<any>();

  const { attendanceList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.attendance);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getAttendanceListData = useCallback(async () => {
    await dispatch(
      AttendanceAction.getAttendanceList({
        search: search,
        limit: limit,
        page: page,
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    getAttendanceListData();
  }, [getAttendanceListData, search, limit, page]);

  return (
    <WebLayout pageName={"AttendanceList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Attendance List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Attendance List</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="header">
              <div
                className="row  d-flex
              justify-content-between
              align-items-end"
              >
                <div className="col-md-3">
                  <label className="mb-2">Show Entries</label>
                  <select
                    onChange={(e) => setLimit(e.target.value)}
                    id="defaultSelect"
                    className="form-select"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <div className="input-group input-group-merge mb-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="table-responsive">
                <table
                  id="basicTable"
                  className="table table-hover table-checkable order-column contact_list"
                >
                  <thead>
                    <tr>
                      <th className="center">#</th>
                      <th className="center">Company Name</th>
                      <th className="center">EMA ID</th>
                      <th className="center">Name</th>
                      <th className="center">Total Hours</th>
                      <th className="center">Total Leaves</th>
                      <th className="center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceList?.length > 0 ? (
                      attendanceList.map((dataList: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{dataList.companyName}</td>
                            <td>{dataList.emp_no}</td>
                            <td>{dataList.name}</td>
                            <td>{dataList.total_hours}</td>
                            <td>{dataList.totalLeave}</td>
                            <td>
                              <Link
                                to={`/ema-wise-attendance-list/${dataList.id}`}
                                className="label label-primary rounded-0"
                              >
                                <i
                                  className="fa fa-eye"
                                  style={{ fontSize: "14px" }}
                                ></i>{" "}
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>No matching records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {attendanceList?.length > 0 ? (
                <div className="row mt-2 justify-content-between">
                  <div className="col-md-auto me-auto ">
                    <div className="dt-info">
                      Showing {from} to {to} of {totalData} entries
                    </div>
                  </div>
                  <div className="col-md-auto ms-auto ">
                    <div className="dt-paging paging_full_numbers">
                      <ul className="pagination">
                        <li
                          className="dt-paging-button page-item disabled"
                          onClick={() => setPage(1)}
                        >
                          <a
                            className="page-link first"
                            aria-controls="tableGroup"
                            aria-disabled="true"
                            aria-label="First"
                            data-dt-idx="first"
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li
                          className={`dt-paging-button page-item prev ${
                            page === 1 ? "disabled" : ""
                          }`}
                          onClick={() =>
                            page === 1 ? "" : setPage(currentPage - 1)
                          }
                        >
                          <a
                            className="page-link previous"
                            aria-controls="tableGroup"
                            aria-disabled="true"
                            aria-label="Previous"
                            data-dt-idx="previous"
                          >
                            <span aria-hidden="true">&lt;</span>
                          </a>
                        </li>
                        {pagination.map((p, index) => {
                          return (
                            <li
                              key={index}
                              className={`dt-paging-button page-item ${
                                currentPage === p ? "active" : ""
                              }`}
                              onClick={() => setPage(p)}
                            >
                              <a
                                className="page-link"
                                href="#"
                                aria-controls="tableGroup"
                                aria-current="page"
                                data-dt-idx="0"
                              >
                                {p}
                              </a>
                            </li>
                          );
                        })}

                        <li
                          className={`dt-paging-button page-item next ${
                            lastPage === page ? "disabled" : ""
                          }`}
                          onClick={() =>
                            lastPage === page ? "" : setPage(currentPage + 1)
                          }
                        >
                          <a
                            href="#"
                            className="page-link next"
                            aria-controls="tableGroup"
                            aria-label="Next"
                            data-dt-idx="next"
                          >
                            <span aria-hidden="true">&gt;</span>
                          </a>
                        </li>
                        <li
                          className="dt-paging-button page-item"
                          onClick={() => setPage(lastPage)}
                        >
                          <a
                            href="#"
                            className="page-link last"
                            aria-controls="tableGroup"
                            aria-label="Last"
                            data-dt-idx="last"
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default AttendanceList;
