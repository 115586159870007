import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import ErmAction from "../../stores/action/ermAction";
import toast from "react-hot-toast";
import ERMDetailsModal from "../../components/erm/ERMDetailsModal";

function ERMList() {
  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [editableData, setEditableData] = useState<string>();
  const [type, setType] = useState<any>();
  const dispatch = useDispatch<any>();

  const {
    ermList,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
    totalPendingKYC,
    totalSendForApprovalKYC,
    totalApprovedKYC,
    totalRejectedKYC,
  } = useSelector((state: RootState) => state.erm);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getERMList = useCallback(async () => {
    await dispatch(
      ErmAction.getERMList({
        limit: limit,
        search: search,
        page: page,
        type: type,
      }),
    );
  }, [dispatch, limit, search, page, type]);

  const updateAccountStatus = async (id: number, status: string) => {
    setBtnLoader(true);

    try {
      await dispatch(
        ErmAction.updateERMPanelStatus({
          id,
          status,
        }),
      );
      await dispatch(
        ErmAction.getERMList({
          limit: limit,
          search: search,
          page: page,
          type: type,
        }),
      );
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setBtnLoader(false);
    }
  };

  useEffect(() => {
    getERMList();
  }, [getERMList, search, limit, page, type]);

  return (
    <WebLayout pageName={"EmployerList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style ">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">ERM Account List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">ERM Account List</li>
                </ul>
              </div>
            </div>
          </div>
          <ERMDetailsModal
            editableData={editableData}
            getERMList={getERMList}
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <button
                    className="btn btn-warning"
                    onClick={() => setType("PENDING")}
                  >
                    KYC Pending List{" "}
                    <span className="badge">{totalPendingKYC}</span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-primary"
                    onClick={() => setType("SEND FOR APPROVAL")}
                  >
                    KYC Send For Approval List{" "}
                    <span className="badge">{totalSendForApprovalKYC}</span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-success"
                    onClick={() => setType("APPROVED")}
                  >
                    KYC Approved List{" "}
                    <span className="badge">{totalApprovedKYC}</span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={() => setType("REJECTED")}
                  >
                    KYC Rejected List{" "}
                    <span className="badge">{totalRejectedKYC}</span>
                  </button>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
                >
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => set_limit(e.target.value)}
                      id="defaultSelect"
                      className="form-select"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => set_search(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">ERM ID</th>
                          <th className="center"> Name</th>
                          <th className="center"> Email</th>
                          <th className="center"> Mobile</th>
                          <th className="center"> State</th>
                          <th className="center"> District</th>
                          <th className="center"> Address</th>
                          <th className="center"> Pin Code</th>
                          <th className="center"> KYC Status</th>
                          <th className="center"> Account Status</th>
                          <th className="center"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ermList?.length > 0 ? (
                          ermList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>N/A</td>
                                <td>{dataList.name}</td>
                                <td>{dataList.email}</td>
                                <td>{dataList.phone}</td>
                                <td>{dataList.state}</td>
                                <td>{dataList.district}</td>
                                <td>{dataList.address}</td>
                                <td>{dataList.pincode}</td>
                                <td>
                                  {dataList?.kyc_status === "APPROVED" ? (
                                    <Link to="#" className="btn btn-success">
                                      {dataList.kyc_status}
                                    </Link>
                                  ) : dataList?.kyc_status ===
                                    "SEND FOR APPROVAL" ? (
                                    <Link to="#" className="btn btn-primary">
                                      {dataList.kyc_status}
                                    </Link>
                                  ) : dataList?.kyc_status === "PENDING" ? (
                                    <Link to={"#"} className="btn btn-warning">
                                      {dataList.kyc_status}
                                    </Link>
                                  ) : dataList?.kyc_status === "REJECTED" ? (
                                    <Link to="#" className="btn btn-danger">
                                      {dataList.kyc_status}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  {dataList?.panel_status === "ACTIVE" ? (
                                    <Link
                                      to="#"
                                      className="btn btn-success"
                                      onClick={() =>
                                        updateAccountStatus(
                                          dataList.id,
                                          "INACTIVE",
                                        )
                                      }
                                    >
                                      ACTIVE
                                    </Link>
                                  ) : dataList?.panel_status === "INACTIVE" ? (
                                    <Link
                                      to="#"
                                      className="btn btn-danger"
                                      onClick={() =>
                                        updateAccountStatus(
                                          dataList.id,
                                          "ACTIVE",
                                        )
                                      }
                                    >
                                      INACTIVE
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <a
                                      className="label label-primary rounded-0"
                                      type="button"
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#offcanvasRight"
                                      onClick={() => {
                                        setEditableData(dataList);
                                      }}
                                      aria-controls="offcanvasRight"
                                    >
                                      <i
                                        className="fa fa-eye"
                                        style={{ fontSize: "14px" }}
                                      ></i>{" "}
                                      View
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {ermList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }
                            >
                              <Link
                                to={"#"}
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </Link>
                            </li>
                            {pagination.map((p, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}
                                >
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0"
                                  >
                                    {p}
                                  </Link>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }
                            >
                              <Link
                                to="#"
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </Link>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}
                            >
                              <Link
                                to="#"
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default ERMList;
