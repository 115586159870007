const EnquiryType = {
  SET_ENQUIRY_LIST: "SET_ENQUIRY_LIST",
  SET_CONTACT_LIST: "SET_CONTACT_LIST",
};

export interface GetEnquiry {
  search: string;
  page: number;
  limit: number;
}

export interface GetContact {
  search: string;
  page: number;
  limit: number;
  type: string;
}
export default EnquiryType;
